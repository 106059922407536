<template>
  <v-app class="app-layout">
    <snack-bar ref="snackbar"></snack-bar>

    <v-app-bar class="indigo lighten-5" fixed flat dense>
      <icon-base width="18" height="18" class="mr-2">
        <icon-check-square-logo />
      </icon-base>
      <span @click="showDashboard" class="text-h6 font-weight-light primary--text text--darken-1" style="cursor:pointer"
        >Telegram Bot Management App</span
      >
      <v-spacer></v-spacer>
      <v-btn
        v-if="user.role_id==1"
        
        class="text-capitalize font-weight-regular ma-2"
        text
        @click="$router.push('/dashboard50')"
      >
        <icon-base
          width="22"
          height="22"
          class="blue-grey--text text--darken-3 mr-1"
        >
          <icon-user />
        </icon-base>
        <span class="mx-1 blue-grey--text text--darken-3">
          Dashboard 2 
        </span>
      </v-btn>
      <v-btn
        v-if="user.role_id==1"
        
        class="text-capitalize font-weight-regular ma-2"
        text
        @click="$router.push('/promocodes')"
      >
        <icon-base
          width="22"
          height="22"
          class="blue-grey--text text--darken-3 mr-1"
        >
          <icon-user />
        </icon-base>
        <span class="mx-1 blue-grey--text text--darken-3">
          PROMOKODLAR 
        </span>
      </v-btn>
      <v-btn
        v-bind="attrs"
        v-if="user.role_id==1"
        v-on="on"
        class="text-capitalize font-weight-regular ma-2"
        text
        @click="$router.push('/paynet')"
      >
        <icon-base
          width="22"
          height="22"
          class="blue-grey--text text--darken-3 mr-1"
        >
          <icon-user />
        </icon-base>
        <span class="mx-1 blue-grey--text text--darken-3">
          PAYNET
        </span>
      </v-btn>
      <v-btn
        
        v-if="user.role_id==1"
        class="text-capitalize font-weight-regular ma-2"
        text
        @click="$router.push('/tgusers')"
      >
        <icon-base
          width="22"
          height="22"
          class="blue-grey--text text--darken-3 mr-1"
        >
          <icon-user />
        </icon-base>
        <span class="mx-1 blue-grey--text text--darken-3">
          USERS 
        </span>
      </v-btn>
      <v-btn
        
        v-if="user.role_id==1"
        class="text-capitalize font-weight-regular ma-2"
        text
        @click="$router.push('/users')"
      >
        <icon-base
          width="22"
          height="22"
          class="blue-grey--text text--darken-3 mr-1"
        >
          <icon-user />
        </icon-base>
        <span class="mx-1 blue-grey--text text--darken-3">
          WEB USERS 
        </span>
      </v-btn>
      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="text-capitalize font-weight-regular ma-2"
              text
            >
              <icon-base
                width="22"
                height="22"
                class="blue-grey--text text--darken-3 mr-1"
              >
                <icon-user />
              </icon-base>
              <span class="mx-1 blue-grey--text text--darken-3">
                {{ user.name }}
              </span>
              <icon-base
                v-if="attrs['aria-expanded'] === 'true'"
                width="16"
                height="16"
                class="ml-1 blue-grey--text text--darken-2"
              >
                <icon-chevron-up />
              </icon-base>
              <icon-base
                v-else
                width="16"
                height="16"
                class="ml-1 blue-grey--text text--darken-2"
              >
                <icon-chevron-down />
              </icon-base>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="logout">
              <v-list-item-content>
                <v-list-item-title>
                  <div class="d-flex">
                    <icon-base width="16" height="16">
                      <icon-logout />
                    </icon-base>
                    <span
                      class="mx-2 blue-grey--text text--darken-2 font-weight-regular"
                      >Logout</span
                    >
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main class="mt-12">
      <transition :name="transitionName" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>

    <v-footer padless>
      <v-card flat tile width="100%" class="indigo lighten-5 text-center">
        <v-card-text class="blue-grey--text">
          <v-row align="center" justify="center">
            <span>&copy;</span>
            <span class="mx-1">Created by</span>
            <a href="https://getinfo.uz" target="_blank" class="blue-grey--text"
              >Iqboljon Shorobidinov</a
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import SnackBar from "@/components/SnackBar";
import { mapGetters } from "vuex";

export default {
  components: {
    SnackBar,
  },

  data() {
    return {
      transitionName: "",
    };
  },

  mounted() {
    this.transitionName = "fade";
  },

  computed: mapGetters({
    user: "auth/user",
  }),

  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    showDashboard(){
      if(this.user.role_id==1){
        this.$router.push("/dashboard");
      }
    }
  },
};
</script>

<style scoped></style>
