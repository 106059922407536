import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/auth/Login.vue";
import $store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      guest: true,
    },
    redirect: {
      name: "auth.login",
    },
  },
  {
    path: "/tgusers",
    name: "tgusers",
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "todos" */ "../views/TgUsers.vue"),
  },
  {
    path: "/map",
    name: "map",
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "todos" */ "../views/map.vue"),
  },
  {
    path: "/users",
    name: "users",
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "todos" */ "../views/Users.vue"),
  },
  {
    path: "/paynet",
    name: "paynet",
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "todos" */ "../views/Paynet.vue"),
  },
  {
    path: "/promocodes",
    name: "promocodes",
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "todos" */ "../views/Promocode.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "todos" */ "../views/DashboardChart.vue"),
  },
  {
    path: "/dashboard50",
    name: "dashboard50",
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "todos" */ "../views/Dashboard50.vue"),
  },
  {
    path: "/main",
    name: "main",
    meta: {
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "todos" */ "../views/Main.vue"),
  },

  // Auth
  {
    path: "/login",
    name: "auth.login",
    meta: {
      guest: true,
    },
    component: Login,
  },
  // {
  //   path: "/register",
  //   name: "auth.register",
  //   meta: {
  //     guest: true,
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "register" */ "../views/auth/Register.vue"),
  // },
  {
    path: "/404",
    name: "pageNotFound",
    meta: {
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "pageNotFound" */ "../views/PageNotFound.vue"
      ),
  },
  {
    path: "*",
    meta: {
      auth: true,
    },
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");
  const user = JSON.parse(loggedIn);

  if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
    next("/login");
    return;
  }

  if (to.matched.some((record) => record.meta.guest) && loggedIn) {
      next('/dashboard');
      return;
  }
  
  if (to.matched.some(record => record.meta.auth) && loggedIn) {
    // Here you can implement your role_id check
    const user = JSON.parse(loggedIn);
    // console.log(user);
    if ((to.name === 'tgusers' || to.name === 'promocodes' || to.name === 'users') && (user && user.user.role_id != 1)) {
      next('/404'); // Redirect to 404 if role_id is not 1
      return;
    }
  }

  next();
});

router.afterEach((to, from) => {
  const path = to.path.substr(1).split("/")[0];

  if (path === "login" || path === "register") {
    $store.commit("SET_LAYOUT", "auth-layout");
  } else {
    $store.commit("SET_LAYOUT", "app-layout");
  }
});

export default router;
